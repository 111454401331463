import React, { useEffect } from "react"
import PropTypes from "prop-types"
import "../styles/styles.scss"
import AOS from 'aos'
import 'aos/dist/aos.css'

const Layout = ({ children }) => {

  useEffect(() => {
    AOS.init({
      duration : 300,
    })
  });

  return (
    <>
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
